@tailwind base;
@tailwind components;
@tailwind utilities;


.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 360px;
    margin-top: 0px;
    align-Items: "center";
  }
  
.block_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 360px;
}

  .dataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 360px;
  }

  .header2048 {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
  }
  
  .form {
    text-align: center;
    color: gray;
    margin-top: 16px;
  }
  
  .submitButton {
    cursor: pointer;
    margin-top: 16px;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    
  }

.temporary-bounce {
    animation-name: bounce-short;
    animation-duration: 0.7s;
    animation-iteration-count: 1;
  }
  @keyframes bounce-short {
    0%, 100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 30px;
    height: 30px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.0s linear infinite;
  }
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 30px;
  }

  .center-image{
    display:flex;
    justify-content:center; /* horizontally center */
    align-items:center;    /* vertically center */
  }

  .tokenImgSize {
    height: 96px;
    width: 96px;
  }

  /* prevent selection for the tile components    */
  .noselect { 
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }